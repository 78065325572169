import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGlobalContextFunction } from "context/GlobalProvider";
import DetailWithSubProduct from "./detail-with-subproduct/DetailWithSubProduct";
import { projectData } from "pages/project/constants";
import "./ProjectDetail.css";

const ProjectDetail: React.FC = () => {
  const { setCurrentPage } = useGlobalContextFunction();
  const { project } = useParams();

  const projectDetailData = project
    ? projectData.find((value) => value.slug === project)
    : undefined;

  useEffect(() => {
    if (!project) return;

    setCurrentPage(project);
  }, [project, setCurrentPage]);

  if (!projectDetailData) return null;

  return (
    <div className="project__detail-container">
      <div className="project__detail-title">{projectDetailData.name}</div>
      <div className="project__detail-desc">{projectDetailData.desc1}</div>
      {projectDetailData?.desc2 && (
        <div className="project__detail-desc">{projectDetailData.desc2}</div>
      )}
      {projectDetailData?.desc3 && (
        <div className="project__detail-desc">{projectDetailData.desc3}</div>
      )}

      {projectDetailData?.has_sub_product && (
        <DetailWithSubProduct
          subProduct={projectDetailData?.sub_product ?? []}
        />
      )}

      <div className="project__detail-images-wrapper">
        {projectDetailData.other_images.map((img, idx) => (
          <img key={`${img}-${idx}`} src={img} alt={`detail-${idx}`} />
        ))}
      </div>
    </div>
  );
};

export default ProjectDetail;
