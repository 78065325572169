type ServicesList = {
  id: number;
  title: string;
  shortDesc: string;
  desc: string;
  link: string;
};

export const servicesList: ServicesList[] = [
  {
    id: 1,
    title: 'spanduk',
    link: '/project/spanduk',
    shortDesc: 'Layanan Cetak spanduk manual printing dengan biaya yang lebih terjangkau solusi promosi yang efektif dan mengesankan. Mencetak spanduk ...',
    desc: 'Layanan Cetak spanduk manual printing dengan biaya yang lebih terjangkau solusi promosi yang efektif dan mengesankan. Mencetak spanduk berkualitas tinggi dengan warna yang kaya, detail yang tajam, dan kejelasan yang luar biasa.'
  },
  {
    id: 2,
    title: 'baliho',
    link: '/project/baliho',
    shortDesc: 'Baliho adalah media promosi berupa papan besar yang dipasang di tempat-tempat strategis seperti pinggir jalan, gedung, atau area publik lainnya ...',
    desc: 'Baliho adalah media promosi berupa papan besar yang dipasang di tempat-tempat strategis seperti pinggir jalan, gedung, atau area publik lainnya. Biasanya terbuat dari bahan kertas atau vinyl yang tahan cuaca, baliho digunakan untuk menampilkan gambar, pesan pemasaran, atau informasi yang ingin disampaikan kepada khalayak umum. Baliho memiliki ukuran yang besar sehingga mudah terlihat dan menarik perhatian.',
  },
  {
    id: 3,
    title: 'display banner',
    link: '/project/display-banner',
    shortDesc: 'Layanan untuk membuat dan mencetak banner promosi yang menarik. Dengan menggunakan teknologi digital, kami mencetak banner dengan gambar, teks, ...',
    desc: 'Layanan untuk membuat dan mencetak banner promosi yang menarik. Dengan menggunakan teknologi digital, kami mencetak banner dengan gambar, teks, dan desain yang jelas dan berwarna-warni. Banner ini dirancang untuk menarik perhatian dan mempromosikan produk, layanan, atau acara dengan tampilan yang profesional.',
  },
  {
    id: 4,
    title: 'print on paper',
    link: '/project/printing-a3',
    shortDesc: 'Print on Paper adalah layanan cetakan sticker ataupun kertas menggunakan ukuran A3 yang lebih kecil, biasanya digunakan untuk keperluan promosi, ...',
    desc: 'Print on Paper adalah layanan cetakan sticker ataupun kertas menggunakan ukuran A3 yang lebih kecil, biasanya digunakan untuk keperluan promosi, pameran, atau dekorasi ruangan. Proses digital printing memungkinkan cetakan dengan resolusi tinggi, detail yang tajam, dan warna yang kaya sehingga menghasilkan gambar yang menarik dan profesional. Large poster ini sering digunakan untuk menarik perhatian dan memberikan pesan yang kuat kepada target audiens.',
  },
  {
    id: 5,
    title: 'wallpaper branding',
    link: '/project/wallpaper-branding',
    shortDesc: 'Proses memasang desain grafis, logo, atau pesan merek pada dinding atau permukaan lain di dalam ruangan, menggunakan sticker ataupun flexi. Ini ...',
    desc: 'Proses memasang desain grafis, logo, atau pesan merek pada dinding atau permukaan lain di dalam ruangan, menggunakan sticker ataupun flexi. Ini melibatkan mencetak gambar atau desain khusus pada kertas dinding atau bahan lain yang sesuai dengan ukuran dan kebutuhan ruangan.',
  },
  {
    id: 6,
    title: 'large sticker',
    link: '/project/large-sticker',
    shortDesc: 'Large Stiker berukuran besar yang dicetak menggunakan teknologi digital. Stiker ini dapat mencakup gambar, logo, teks, atau desain kustom dengan ...',
    desc: 'Large Stiker berukuran besar yang dicetak menggunakan teknologi digital. Stiker ini dapat mencakup gambar, logo, teks, atau desain kustom dengan resolusi tinggi dan warna yang tajam. Dengan kemampuan digital printing, stiker besar ini dapat menghadirkan hasil cetakan yang detail, tahan lama, dan menarik, sehingga cocok digunakan untuk keperluan promosi, branding, atau dekorasi yang membutuhkan tampilan yang mencolok dan eye-catching.',
  },
  {
    id: 7,
    title: 'event organizer',
    link: '/project/event-organizer',
    shortDesc: 'Layanan yang menyediakan bantuan profesional dalam perencanaan, pengorganisasian, dan pelaksanaan acara. Event organizer bertanggung jawab untuk ...',
    desc: 'Layanan yang menyediakan bantuan profesional dalam perencanaan, pengorganisasian, dan pelaksanaan acara. Event organizer bertanggung jawab untuk mengkoordinasikan berbagai aspek acara, termasuk pemilihan lokasi, pengelolaan vendor, manajemen anggaran, pengaturan teknis, dan koordinasi keseluruhan acara. Mereka bekerja sama dengan klien untuk memastikan acara berjalan lancar, sesuai dengan visi dan tujuan yang diinginkan. ',
  },
  {
    id: 8,
    title: 'car branding',
    link: '/project/car-branding',
    shortDesc: 'Car branding adalah layanan cetak stiker khusus yang digunakan untuk menempelkan desain grafis, logo, atau pesan pemasaran pada kendaraan. Stiker ...',
    desc: 'Car branding adalah layanan cetak stiker khusus yang digunakan untuk menempelkan desain grafis, logo, atau pesan pemasaran pada kendaraan. Stiker ini dirancang untuk mengubah penampilan visual kendaraan menjadi alat promosi yang berjalan secara mobile. Car branding sticker biasanya dicetak dengan teknologi digital printing untuk mencapai resolusi tinggi dan warna yang tajam.',
  },
  {
    id: 9,
    title: 'banner',
    link: '/project/banner',
    shortDesc: 'Cetak banner digital kami menawarkan solusi promosi yang menarik dan profesional. Dengan teknologi terkini, kami menghasilkan banner berkualitas ...',
    desc: 'Cetak banner digital kami menawarkan solusi promosi yang menarik dan profesional. Dengan teknologi terkini, kami menghasilkan banner berkualitas tinggi dengan tampilan yang hidup dan detail yang memukau',
  },
  {
    id: 10,
    title: 'sablon kaos',
    link: '/project/sablon-kaos',
    shortDesc: 'Jasa sablon kaos Devta menawarkan kesempatan bagi pelanggan untuk memenuhi kebutuhan bisnis atau perorangan melalui desain kaos yang kreatif. Kami ...',
    desc: 'Jasa sablon kaos Devta menawarkan kesempatan bagi pelanggan untuk memenuhi kebutuhan bisnis atau perorangan melalui desain kaos yang kreatif. Kami menggunakan teknik sablon manual yang berkualitas untuk memastikan bahwa kaos yang dihasilkan memiliki tampilan yang menarik dan tahan lama.',
  },
];
