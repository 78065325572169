import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalContextFunction } from 'context/GlobalProvider';
import { HeadlineText } from 'components/headline-text';
import { projectData } from './constants';

import './Project.css';


const Project: React.FC = () => {
  const navigate = useNavigate();
  const { setCurrentPage } = useGlobalContextFunction();

  useEffect(() => {
    setCurrentPage('project');
  }, [setCurrentPage]);

  return (
    <div className="project__container">
      <div className="project__top-desc">
        <p>
          Apakah kamu membutuhkan jasa percetakan digital printing yang
          berkualitas dan terpercaya?
        </p>
        <h4>DEVTA adalah solusinya!</h4>
        <p>
          Berikut berbagai project dari berbagai klien DEVTA sebagai referensi
          kamu.
        </p>
      </div>
      <div className="project__card-wrapper">
        {projectData.map((project) => (
          <div key={project.id} className="project__card">
            <div className="project__btn" onClick={() => navigate(project.link)}>
              <div>{project.name}</div>
            </div>
            <img src={project.image} alt={project.name} />
          </div>
        ))}
      </div>
      <div className="project__headline-text">
        <HeadlineText text="PT. Devta merupakan perusahaan Printing terpercaya selama 20 tahun" />
      </div>
    </div>
  );
};

export default Project;
